<template>
  <div class="login-right">
    <div class="login_box">
      <div class="title">欢迎登录</div>
      <div class="content">
        <!-- 账密、手机 -->
        <a-form id="formLogin" class="user-layout-login ruleForm" ref="formLogin" :form="form" @submit="handleSubmit">
          <a-form-item label="用户名">
            <a-input
              size="large"
              type="text"
              placeholder="请输入账号"
              v-decorator="[
                'account',
                {
                  initialValue: '',
                  rules: [{ required: true, message: '请输入账号' }, { validator: handleUsernameOrEmail }],
                  validateTrigger: 'blur'
                }
              ]"
            >
            </a-input>
          </a-form-item>

          <a-form-item label="密码">
            <a-input-password
              size="large"
              type="password"
              autocomplete="false"
              placeholder="请输入密码"
              v-decorator="[
                'password',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: '请输入密码' },
                    { min: 6, max: 16, message: '请输入6-16位的密码' }
                  ],
                  validateTrigger: 'blur'
                }
              ]"
            >
            </a-input-password>
          </a-form-item>

          <!-- <a-form-item style="margin:0.15rem auto 0.25rem;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">记住密码</a-checkbox>
              <div class="forge-password" @click="showForgetPwdModal">忘记密码?</div>
            </div>
          </a-form-item> -->

          <a-form-item style="margin-bottom:0;text-align:center;">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
              >立即登录</a-button
            >

            <!-- <div class="clause" @click="showRegisterModal">还没有账号?<span>去注册</span></div> -->
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 底部注册、下载 -->
    <div class="login-right-bottom">
      <div class="bottom2">
        <div>
          <!-- 四川省移民后期扶持 -->
        </div>
        <div>
          <!-- 蜀ICP备2022028199号-1 -->
        </div>
      </div>
    </div>

    <forgetPwdModal ref="forgetPwd" />
    <!-- 注册弹窗 -->
  </div>
</template>

<script>
import Vue from 'vue'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import {
  getSmsCaptcha,
  getCaptchaOpen,
  _verifyPhoneVerify,
  _codeLogin,
  _createImageVerify,
  _verifyImageVerify
} from '@/api/modular/system/loginManage'
import Verify from '@/components/verifition/Verify'
import MD5 from '@/common/lib/md5.min.js'
import { debounce } from '@/utils/index'
import { extractChinese } from '@/utils/util'
import forgetPwdModal from './forgetPwdModal.vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  components: {
    TwoStepCaptcha,
    Verify,
    forgetPwdModal
  },
  data() {
    return {
      activeKey: 'user',
      activeIndex: 'login',
      loginQrCode: {
        key: '',
        loginIp: '',
        loginAddress: '',
        loginName: '',
        action: ''
      },
      code: '', // 保存生成的二维码数据
      accessToken: null, // 保存accessToken
      isLogin: 0, // 保存是否登录的状态

      phoneNumber: '',
      verificationCode: '',
      showButton: true,
      countDown: 60,
      loading: false,
      phoneNumberRules: [
        { required: true, message: '请输入手机号码' },
        { pattern: /^1\d{10}$/, message: '请输入正确的手机号码' }
      ],
      verificationCodeRules: [{ required: true, message: '请输入验证码' }],

      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      form: this.$form.createForm(this),
      codeLogin: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      // accountLoginErrMsg: '',
      tenantOpen: false,
      captchaOpen: false, // 是否开启验证码
      tenantsList: [],
      loginParams: [], // 登录参数

      // 验证码
      code: {},
      phonecode: {},
      timer2: null
    }
  },
  created() {
    this.getCode()
  },
  beforeRouteLeave(to, from, next) {
    // 清除定时器
    clearInterval(this.timer2)
    clearInterval(this.timer)
    // 继续路由跳转
    next()
  },
  async mounted() {
    this.getLocalStorageData()
  },
  methods: {
    showRegisterModal() {
      this.$emit('changeActiveIndex', 'reg')
    },
    // showForgetPwdModal() {
    //   this.$refs.forgetPwd.showModal()
    // },

    // 点击按钮时触发的事件处理器
    handleFetchCode: debounce(function() {
      const {
        codeLogin: { validateFields },
        state,
        Login
      } = this
      const validateFieldsKey = ['mobile', 'verifyCode']
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          // TODO: 调用后端接口请求发送验证码
          const params = {
            createCodeParam: {
              characterCount: 6,
              type: 1
            },
            verifyCodeKey: this.code.key,
            verifyCode: values.verifyCode,
            smsType: 3,
            phone: values.mobile,
            verifyCodeType: 1
          }

          _verifyPhoneVerify(params).then(res => {
            this.showButton = false
            if (res.code == 200) {
              this.phonecode = res.data
              // console.log(this.phonecode, "点击验证码之后----");
              this.loading = true
              // TODO: 调用后端接口请求发送验证码

              // 假设接口返回成功，开始倒计时

              this.countDown = 60

              this.timer = setInterval(() => {
                if (this.countDown === 1) {
                  clearInterval(this.timer)
                  this.showButton = true
                }
                this.countDown -= 1
              }, 1000)

              // 接口请求完成后重置 loading 状态
              setTimeout(() => {
                this.loading = false
              }, 1000)
            } else {
              this.$message.warning(res.message)
              this.showButton = true
              this.$message.warning(extractChinese(res.message))
              // // 手机号codeclear
              // this.codeLogin.setFieldsValue({
              //   verifyCode: null
              // })
              clearInterval(this.timer)
            }
          })
        } else {
          this.$message.warning('请先输入手机号！')
        }
      })
    }, 300),

    ...mapActions(['Login', 'Logout', 'dictTypeData']),
    // 获取图形验证码（旧demo）
    getCode() {
      const params = {
        createCodeParam: {
          characterCount: 4,
          type: 1
        },
        fontSize: 16,
        verifyCodeType: 1
      }
      _createImageVerify(params).then(res => {
        // 获取成功
        if (res.code == 200) {
          this.code = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 获取验证码开关
     */
    getCaptchaOpen() {
      getCaptchaOpen().then(res => {
        if (res.success) {
          this.captchaOpen = res.data
        }
      })
    },
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login
      } = this

      state.loginBtn = true
      const validateFieldsKey = ['account', 'password', 'verifyCode']
      if (this.tenantOpen) {
        validateFieldsKey.push('tenantCode')
      }
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        this.loginParams = values
        if (!err) {
          // 是否开启验证码
          if (this.captchaOpen) {
            this.$refs.verify.show()
            state.loginBtn = false
            return
          }
          const loginParams = { ...values }
          delete loginParams.account
          loginParams[!state.loginType ? 'account' : 'account'] = values.account
          // loginParams.password = values.password
          loginParams.password = MD5(values.password)
          if (this.tenantOpen) {
            loginParams.tenantCode = values.tenantCode
          }
          let params = {
            verifyCodeKey: this.code.key,
            verifyCodeType: this.code.verifyCodeType,
            loginClientType: 0
          }
          if (this.activeKey == 'user') {
            params.verifyCode = '1122' //后端验证非空、写死
          }
          // verifyCode: this.code.code,
          Login({ ...loginParams, ...params })
            .then(res => this.loginSuccess(res))
            .catch(err => this.requestFailed(JSON.stringify(err)))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
            // this.getCode()
          }, 600)
        }
      })
    },
    getCaptcha(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state
      } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile })
            .then(res => {
              setTimeout(hide, 2500)
              this.$notification['success']({
                message: '提示',
                description: '验证码获取成功，您的验证码为：' + res.result.captcha,
                duration: 8
              })
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    loginSuccess(res) {
      this.setLocalStorageData()
      // this.$router.push({ path: '/' })
      this.$router.push({ path: '/socialDevelopment' })
      this.isLoginError = false
      // 加载字典所有字典到缓存中
      this.dictTypeData().then(res => {})
    },
    requestFailed(err) {
      this.$message.error(extractChinese(err))
      this.getCode()
      // // 清空user登录二维码input
      // this.form.setFieldsValue({
      //   verifyCode: null
      // })

      // this.accountLoginErrMsg = err
      this.isLoginError = true
    },
    /**
     * 从 localStorage 中读取信息
     */
    getLocalStorageData() {
      const account = Vue.ls.get('LOGIN_ACCOUNT')
      const password = Vue.ls.get('LOGIN_PASSWORD')
      if (account && password) {
        this.form.setFieldsValue({
          account: account,
          password: password,
          rememberMe: true
        })
      }
    },
    /**
     * 将信息写入 localStorage
     */
    setLocalStorageData() {
      if (this.form.getFieldValue('rememberMe')) {
        Vue.ls.set('LOGIN_ACCOUNT', this.form.getFieldValue('account'))
        Vue.ls.set('LOGIN_PASSWORD', this.form.getFieldValue('password'))
      } else {
        Vue.ls.remove('LOGIN_ACCOUNT')
        Vue.ls.remove('LOGIN_PASSWORD')
      }
    }
  }
}
</script>

<style lang="less" scoped>
button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 50%;
  border: none;
  border-radius: 14px;
  opacity: 1;
  background: #3f8cff;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.2637);
}

.login-right {
  flex: 0 1 47%;
  background: #fff;
  padding: 0.05rem 0.6875rem 0;
  border-radius: 0 24px 24px 0;
  // overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .login_box {
    width: 90%;
  }
  .title {
    width: 100%;
    margin-bottom: 0.4rem;
    font-family: PingFang SC;
    font-size: 0.275rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0px;
    color: #0a1629;
  }

  .login-right-bottom {
    width: 100%;
    height: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    padding: 0.05rem 0.6875rem 0;
    .bottom1 {
      display: flex;
      justify-content: space-between;
      font-size: 0.1875rem;
      color: #1890ff;
      cursor: pointer;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
    }

    .bottom2 {
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      margin-top: 0.1rem;
    }
  }
}

.forge-password {
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #515151;
  cursor: pointer;
  font-size: 0.1875rem;
  text-decoration: underline;
  float: right;
}

.el-input__inner {
  background-color: transparent !important;
}

/deep/ .ant-input-affix-wrapper .ant-input {
  border-radius: 11px;
}

/deep/ .ant-btn-primary {
  border-radius: 23px;
}

.user-layout-login {
  .clause {
    display: flex;
    justify-content: center;
    color: #3f8cff;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16;
    & > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.qr-disabled {
  filter: grayscale(100%) im !important;
}
</style>
