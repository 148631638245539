<template>
  <div style="overflow: hidden;">
    <!-- <a-button type="primary" @click="showModal">
            修改密码
        </a-button> -->

    <!-- 3.修改密码框 -->
    <a-modal v-model="registerVisible" :footer="null" :centered="true" @ok="handleOk" @cancel="handleCancel" :maskClosable="false" :keyboard="false">
      <template slot="title">
        <h2 class="reg-title">忘记密码</h2>
        <!-- <div class="reg-other">想起来了?<a href="#" @click="goToLogin">去登录</a></div> -->
      </template>

      <div style="margin-top: 0.2rem;padding: 1px;">
        <a-form id="formLogin" class="user-layout-login ruleForm" ref="formReg" :form="form" @submit="handleSubmit">
          <a-alert v-if="isLoginError" type="error" style="margin-bottom: 24px;" :message="this.accountRegErrMsg" />

          <a-form-item>
            <a-input :maxLength="11" size="large" type="text" placeholder="请输入手机号" v-decorator="[
                'mobile',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: '请输入手机号码' },
                    { max: 11, message: '手机号码最长不能超过11位' },
                    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码' }
                  ],
                  validateTrigger: 'blur'
                }
              ]">
            </a-input>
          </a-form-item>


          <a-form-item>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <a-input :maxLength="4" size="large" style="width: 50%;" type="text" autocomplete="false" placeholder="验证码" v-decorator="[
                  'verifyCode',
                  { initialValue: '', rules: [{ required: true, message: '请输入验证码'},{ max: 4, message: '验证码不能超过4位' }], validateTrigger: 'blur' }
                ]">
              </a-input>
              <img :src="'data:image/jpg;base64,' + code.base64Image" alt="" class="codeImg"
                style="width: 1.3rem;height: 0.5rem;cursor: pointer;border-radius: 5px;" @click="getCode()" />
            </div>
          </a-form-item>


          <a-form-item>
            <a-input :maxLength="6" size="large" type="text" placeholder="请输入验证码" v-decorator="[
                'captcha',
                {
                  initialValue: '',
                  rules: [{ required: true, message: '请输入验证码'},{ max: 6, message: '验证码不能超过6位' }],
                  validateTrigger: 'blur'
                }
              ]">
              <template #suffix>
                <a-button type="link" :disabled="!showButton" @click="handleFetchCode">
                  {{ showButton ? '获取验证码' : `(${countDown}) 秒后重试` }}
                </a-button>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-password :maxLength="16" size="large" type="password" autocomplete="false" placeholder="请输入密码" v-decorator="[
              'password',
              {
                initialValue: '',
                rules: [
                  { required: true, message: '请输入密码' },
                  { min: 6, max: 16, message: '请输入6-16位的密码' },
                ],
                validateTrigger: 'blur'
              }
            ]">
            </a-input-password>
          </a-form-item>

          <a-form-item>
            <a-input-password :maxLength="16" size="large" type="password" autocomplete="false" placeholder="再次确认密码" v-decorator="[
                'confirmPassword',
                { initialValue: '', rules: [{ required: true, message: '请再次确认密码' }], validateTrigger: 'blur' }
              ]">
            </a-input-password>
          </a-form-item>

          <a-form-item style="margin-top:0.2rem;text-align:center;">
            <a-button size="large" type="primary" htmlType="submit" class="reg-button"
              :disabled="state.regBtn">提交</a-button>
          </a-form-item>

          <!-- <a-form-item style="margin-top:0.2rem;text-align:center;">
            <router-link to="/htmls/rule.html" target="_blank">
              <div>
                注册即同意《服务条款》
              </div>
            </router-link>
          </a-form-item> -->
        </a-form>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { _phoneValidate, _passwordValidate } from '@/utils/validate'
import { _createImageVerify, _verifyPhoneVerify, _forgetPwd, _verifyImageVerify } from '@/api/modular/system/loginManage'
import templateTitle from '@/components/templateTitle.vue'
import { debounce } from '@/utils/index'

export default {
  components: { templateTitle },
  data() {
    return {
      agree: false,
      registerVisible: false, //注册
      // registerVisible1: false, //修改密码框
      showButton: true,
      countDown: 60,
      state: {
        time: 60,
        regBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      form: this.$form.createForm(this),
      codeReg: this.$form.createForm(this),
      isLoginError: false,
      accountRegErrMsg: '',
      regParams: [], // 注册参数
      // 验证码
      code: {}
    }
  },
  created() {

  },
  methods: {
    showModal() {
      this.registerVisible = true
      this.getCode()
    },
    goToLogin() {
      this.registerVisible = false
    },
    // 获取图形验证码
    getCode() {
      const params = {
        createCodeParam: {
          characterCount: 4,
          type: 1
        },
        fontSize: 16,
        verifyCodeType: 1
      };
      _createImageVerify(params).then(res => {
        // 获取成功
        if (res.code == 200) {
          this.code = res.data;
        } else {
          this.$message.error("获取验证码失败");
        }
      })
    },

    // 点击按钮时触发的事件处理器
    handleFetchCode: debounce(function () {
      const {
        form: { validateFields }
      } = this
      const validateFieldsKey = ['verifyCode', "mobile"]
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          // TODO: 调用后端接口请求发送验证码
          const params = {
            "createCodeParam": {
              "characterCount": 6,
              "type": 1
            },
            "verifyCodeKey": this.code.key,
            "verifyCode": values.verifyCode,
            "smsType": 2,//找回密码
            "phone": values.mobile,
            "verifyCodeType": 1
          }

          _verifyPhoneVerify(params).then((res) => {

            this.showButton = false;

            // 假设接口返回成功，开始倒计时
            if (res.code == 200) {
              this.code = { ...this.code, ...res.data };
              // 接口请求完成后重置 loading 状态
              this.countDown = 60
              this.timer = setInterval(() => {
                if (this.countDown === 1) {
                  clearInterval(this.timer)
                  this.showButton = true
                }
                this.countDown -= 1
              }, 1000)

              setTimeout(() => {
                this.loading = false
              }, 1000)

            } else {
              this.$message.error(res.message);
              this.showButton = true;
              clearInterval(this.timer);
            }
          })
        } else {
          this.$message.error('请先输入验证码！')
        }
      })
    }, 300),

    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state
      } = this

      // state.regBtn = true;

      const validateFieldsKey = ['mobile', 'password', 'captcha', "confirmPassword"]

      validateFields(validateFieldsKey, { force: true }, (err, values) => {


        this.regParams = { ...this.code, ...values };

        if (this.regParams.password == this.regParams.confirmPassword) {

          if (!err) {
            const params = {
              verifyCodeKey: this.regParams.key,
              verifyCode: this.regParams.captcha,
              phone: this.regParams.mobile,
              verifyCodeType: 3,
              password: this.regParams.password
            }

            _forgetPwd(params).then((res) => {

              if (res.code == 200) {
                this.$message.success("修改密码成功");
                this.registerVisible = false;
                state.regBtn = true;
                this.form.resetFields()
              } else {
                this.$message.error(res.message);
                state.regBtn = false;
                return false
              }
            })
          }

        } else {
          this.$message.error("两次输入密码不一致");
          state.regBtn = false
          return false
        }

      })
    },

    handleOk() { },
    handleCancel() {
      this.showButton = true;
      this.form.resetFields();
    },
  }
}
</script>
<style lang="scss" scoped>
.my-RegModal {
  border: 2px solid red;
}

::v-deep .ant-modal-header {
  border-bottom: 0;
}

::v-deep .ant-modal-content {
  width: 4.5rem;
  height: 5.5rem;
  border-radius: 10px;
}

::v-deep .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

::v-deep .ant-row {
  display: flex;
  justify-content: center;
}

::v-deep .ant-form-item {
  margin-bottom: 0;
}

::v-deep .ant-form-item-children input {
  border: 0;
  border-bottom: 1px solid #eeeeee;
}

::v-deep .ant-form-item-children input::placeholder {
  font-size: 12px;
  color: #d7d7d7;
  font-weight: 500;
}

::v-deep .ant-form-item-children input:focus {
  border: 0;
  border-bottom: 1px solid #1966ff;
}

::v-deep .ant-form-item-children input:hover {
  border: 0;
  border-bottom: 1px solid #1966ff;
}

::v-deep .ant-form-item-children input:blur {
  border: 0;
  border-bottom: 1px solid #1966ff;
}

::v-deep .ant-form {
  margin: -0.375rem 0;
}

.reg-title {
  font-size: 16px;
  font-weight: 800;
  color: #333;
  font-family: PingFang SC-Medium, PingFang SC;
}

.reg-other {
  color: #818c98;
  font-size: 12px;
  font-weight: 500;
  font-family: PingFang SC-Medium, PingFang SC;

  a {
    color: #4689f6;
  }
}

.regbtn {
  width: 100%;
  height: 33px;
  background: #4189fe;
  border-radius: 17px 17px 17px 17px;
}

.custom-form-item .ant-input:focus {
  border-color: green;
}

.custom-input:focus {
  border-color: green;
}

::v-deep .ant-form-item-children {
  display: block;
  width: 100%;

  input {
    width: 100%;
  }
}

::v-deep .ant-modal-body {
  padding: 0 0.25rem;
  height: 3.5rem;
  margin-top: 0.25rem;
}

::v-deep .ant-col {
  width: 100%;
}

button.reg-button {
  padding: 0 0.1875rem;
  font-size: 0.2rem;
  height: 0.5rem;
  width: 100%;
  border-radius: 0.2125rem;
  background: #4189fe;
  border: none;
  color: #ffffff;
}
</style>
